<template>
	<div id="app">
		<router-view />
		<!-- <sidebar /> -->
		<ChatComp />
		<div style="opacity: 0; width: 0; height: 0" id="footer"></div>
	</div>
</template>

<script>
	import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
	// import sidebar from "../src/views/Sidebar/index.vue";
	import ChatComp from '@/components/ChatComp.vue';
	export default {
		name: '',
		components: {
			// sidebar,
			ChatComp
		},
		data() {
			return {};
		},
		computed: {
			...mapGetters(['isLogin']),
			...mapState(['language'])
		},
		created() {
			this.init();
		},
		methods: {
			...mapActions(['ReFreshUserInfo', 'getCartList']),
			...mapMutations(['setUserInfo']),
			init() {
				// console.log("是否登录", this.isLogin);
				this.getDeviceType();
				const userInfo = localStorage.getItem('userInfo');
				if (userInfo) {
					this.setUserInfo(JSON.parse(userInfo));
				}
				if (this.isLogin) {
					this.ReFreshUserInfo();
					this.getCartList();
				}
				// this.getHomeGoods();
				addEventListener('resize', () => {
					this.getDeviceType();
				});
			},
			// 获取设备分辨率
			getDeviceType() {
				let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
				console.log('设备宽度', width);
				// if (width < 750) {
				//   this.setDeviceType(true);
				// } else {
				//   this.setDeviceType(false);
				// }
			},
			//判断访问设备
			_isMobile() {
				let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
				return flag;
			}
		}
	};
</script>

<style lang="scss">
	@font-face {
		font-family: 'Roboto';
		src: url('./assets/fonts/Roboto.ttf') format('truetype');
	}
	// @import "./styles/bootstrap.css";
	@import './styles/base.scss';
	@import './styles/common.scss';
	// @import "./assets/fonts/FZZ.css";
</style>
