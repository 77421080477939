import axios from './axios';
// import { Message } from "element-ui";
// post模版
// export function postModelApi(params) {
// 	return axios({
// 		url: '/user/register',
// 		data: params,
// 		method: 'post'
// 	});
// }
// get模版
// export function getModelApi(params) {
// 	return axios({
// 		url: '/order/address',
// 		params
// 	});
// }
// 获取首页产品分类
export function getHomeProductApi(params) {
	return axios({
		url: '/goods/cate',
		params
	});
}
// 获取首页热门产品推荐
export function getHomeProductListApi(params) {
	return axios({
		url: '/goods/index_hot',
		params
	});
}
// 获取一级或者二级分类下的商品
export function getPageProductListApi(params) {
	return axios({
		url: '/goods/list',
		params
	});
}
// 获取轮播图
export function getBannerListApi(params) {
	return axios({
		url: '/common/banners',
		params
	});
}
// 获取商品详情
export function getGoodsdetailApi(params) {
	return axios({
		url: '/goods/detail',
		params
	});
}
// 登录
export function loginApi(params) {
	return axios({
		url: '/user/login',
		data: params,
		method: 'post'
	});
}
// 注册
export function registerApi(params) {
	return axios({
		url: '/user/register',
		data: params,
		method: 'post'
	});
}
// 反馈
export function submitFeedbackApi(params) {
	return axios({
		url: '/common/submit',
		data: params,
		method: 'post'
	});
}
// 发送邮箱验证链接
export function checkEmailApi(params) {
	return axios({
		url: '/ems/send',
		data: params,
		method: 'post'
	});
}
// 验证邮箱是否有效
export function checkEmailTimeApi(params) {
	return axios({
		url: '/ems/check',
		data: params,
		method: 'post',
		isShowError: false
	});
}
//添加购物车
export function addCarApi(params) {
	return axios({
		url: '/cart/add',
		data: params,
		method: 'post',
		isShowError: true
	});
}
//删除购物车
export function delCarApi(params) {
	return axios({
		url: '/cart/del',
		data: params,
		method: 'post'
	});
}
// 购物车列表
export function carListApi(params) {
	return axios({
		url: '/cart/list',
		params
	});
}
// 购物车商品数量变化
export function changeCarNumApi(params) {
	return axios({
		url: '/cart/set_nums',
		data: params,
		method: 'post',
		isShowError: true
	});
}
// 获取订单列表
export function getorderApi(params) {
	return axios({
		url: '/order/list',
		params
	});
}
// 文件上传
export function UploadApi(params) {
	return axios({
		url: '/common/upload',
		'Content-Type': 'multipart/form-data',
		data: params,
		method: 'post'
	});
}
// 修改头像和昵称
export function SetprofileApi(params) {
	return axios({
		url: '/user/profile',
		data: params,
		method: 'post'
	});
}
// 获取用户信息
export function getUserInfoApi(params) {
	return axios({
		url: '/user/info',
		params
	});
}
// 修改密码
export function SetpassApi(params) {
	return axios({
		url: '/user/updatePassword',
		data: params,
		method: 'post'
	});
}
// 提交订单
export function orderSubmitApi(params) {
	return axios({
		url: '/order/submit',
		data: params,
		method: 'post'
	});
}
// 支付
export function orderPayApi(params) {
	return axios({
		url: '/pay/order_pay',
		data: params,
		method: 'post'
	});
}
// 重置密码
export function resetPasswordApi(params) {
	return axios({
		url: '/user/resetpwd',
		data: params,
		method: 'post'
	});
}
// 更新邮箱
export function UPdataEmailApi(params) {
	return axios({
		url: '/user/changeemail',
		data: params,
		method: 'post'
	});
}
// 批量加入购物车
export function addCartAllApi(params) {
	return axios({
		url: '/cart/addAll',
		data: params,
		method: 'post'
	});
}
// 订单取消支付
export function orderCancelApi(params) {
	return axios({
		url: '/order/cancel',
		data: params,
		method: 'post'
	});
}
// 获取底部列表
export function getfoootlistApi(params) {
	return axios({
		url: '/article/data',
		params
	});
}
// 获取底部信息详情
export function getfootdetailApi(params) {
	return axios({
		url: '/article/detail',
		params
	});
}
