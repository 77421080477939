<template>
	<van-pagination class="comp-page" v-bind="$attrs" v-on="$listeners">
		<template #prev-text>
			<div class="page-left"></div>
		</template>
		<template #next-text>
			<div class="page-left page-right"></div>
		</template>
		<template #page="{ text }">
			<div class="page-active">
				{{ text }}
			</div>
		</template>
	</van-pagination>
</template>

<script>
	export default {};
</script>

<style lang="scss">
	[class*='van-hairline']::after {
		border: none;
	}
	.van-pagination__item {
		min-width: unset;
		height: unset;
		color: unset;
	}
	.van-pagination__item--active {
		background-color: unset;
	}
	.van-pagination__item--disabled {
	}
	.comp-page {
		.page-left {
			width: 0.22rem;
			height: 0.22rem;
			background: url('~@/assets/images/left-arrow-active.png') no-repeat;
			background-size: 100% 100%;
		}
		.page-right {
			transform: rotate(180deg);
		}
		.van-pagination__item--disabled {
			.page-left {
				background: url('~@/assets/images/left-arrow.png') no-repeat;
				background-size: 100% 100%;
			}
		}
		.page-active {
			padding: 0 0.4rem;
			font-size: 0.2rem;
		}
	}
</style>
