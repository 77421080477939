export default {
	setUserInfo(state, userInfo) {
		state.userInfo = userInfo;
		localStorage.setItem('userInfo', JSON.stringify(userInfo));
	},
	setCarList(state, list) {
		state.carList = list;
	},
	setHomeProductList(state, list) {
		state.homeProductList = list;
	}
};
