import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法，解决vue路由重复导航错误
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};
const routes = [
	{
		path: '/',
		name: 'WrapIndex',
		component: () => import('@/views/WrapIndex/index.vue'),
		redirect: '/Home',
		children: [
			{
				path: '/Home',
				name: 'Home',
				component: () => import('@/views/Home/index.vue')
			},
			{
				path: '/goodsdetail',
				name: 'goodsdetail',
				component: () => import('@/views/Goods/goodsdetail.vue')
			},
			{
				path: '/goodsList',
				name: 'goodsList',
				component: () => import('@/views/Goods/goodsList.vue')
			},
			{
				path: '/user',
				name: 'user',
				component: () => import('@/views/User/index.vue')
			},
			{
				path: '/cartDetail',
				name: 'cartDetail',
				component: () => import('@/views/cartDetail/index.vue')
			},
			{
				path: '/forgetStepOne',
				name: 'forgetStepOne',
				component: () => import('@/views/forget/forgetStepOne.vue')
			},
			{
				path: '/forgetStepTwo',
				name: 'forgetStepTwo',
				component: () => import('@/views/forget/forgetStepTwo.vue')
			},
			{
				path: '/forgetStepThree',
				name: 'forgetStepThree',
				component: () => import('@/views/forget/forgetStepThree.vue')
			},
			{
				path: '/Pact',
				name: 'Pact',
				component: () => import('@/views/Pact/index.vue')
			},
			{
				path: '/Serves',
				name: 'serves',
				component: () => import('../components/Serves.vue')
			}
		]
	},
	{
		path: '/successPage',
		name: 'successPage',
		component: () => import('@/views/forget/successPage.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});
export default router;
