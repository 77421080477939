<template>
	<div class="comp-chat">
		<div v-if="!isOpenChat" @click="kefuOnClick" class="right-contect point">
			<div class="icon">
				<img style="height: 100%" src="../assets/images/phone-icon.png" alt="" />
			</div>
			<div class="text">Contact us</div>
		</div>
		<div v-else @click="kefuOnClick" class="right-contect-close point"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isOpenChat: false
			};
		},
		methods: {
			kefuOnClick() {
				console.log(3333, drift.api);
				this.isOpenChat = !this.isOpenChat;
				if (!this.isOpenChat) {
					drift.api.hideChat();
				} else {
					drift.api.openChat();
				}
			}
		}
	};
</script>

<style lang="scss">
	.right-contect {
		position: fixed;
		right: 0.2rem;
		top: 3.4rem;
		// transform: translateY(-50%);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.4rem;
		height: 0.52rem;
		background: #41cc8b;
		border-radius: 0.24rem 0.24rem 0.04rem 0.24rem;
		box-shadow: 0rem 0.1rem 0.25rem 0rem rgba(45, 193, 103, 0.2), 0.02rem 0.01rem 0.03rem 0rem rgba(45, 193, 103, 0.15);
		.icon {
			width: 0.22rem;
			height: 0.22rem;
			margin-right: 0.06rem;
		}
		.text {
			font-size: 0.16rem;
			color: #ffffff;
			-webkit-user-select: none; /* Safari */
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* IE/Edge */
			user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
		}
	}
	.right-contect-close {
		position: fixed;
		right: 0.2rem;
		top: 3.4rem;
		// transform: translateY(-50%);
		width: 0.52rem;
		height: 0.52rem;
		background: url('~@/assets/images/right-close-icon.png') no-repeat;
		background-size: 100% 100%;
	}
</style>
