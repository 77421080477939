import { debounce } from 'lodash';
import { carListApi, getUserInfoApi, addCartAllApi } from '@/network/api.js';
export default {
	// 获取购物车列表
	async getCartList(context) {
		const res = await carListApi();
		console.log('购物车列表', res.data?.Items);
		context.commit('setCarList', res.data?.Items);
	},
	// 退出登录
	async loginOut(context) {
		context.commit('setUserInfo', {});
		localStorage.removeItem('userInfo');
		context.commit('setCarList', []);
	},
	//获取 刷新用户信息
	async ReFreshUserInfo(context) {
		const res = await getUserInfoApi();
		// 将本地的 token 数据加入到从 API 获取的对象数据中
		const newData = {
			...res.data,
			token: context.state.userInfo.token // 将本地 token 数据加入到新的数据对象中
		};
		console.log(newData, '用户信息');
		localStorage.setItem('userInfo', JSON.stringify(newData));
		context.commit('setUserInfo', newData);
	},
	// 合并购物车
	mergeCartList: debounce(async (context) => {
		console.log('合并购物车');
		const { state } = context;
		const params = {
			data: JSON.stringify(
				state.carList.map((item) => {
					return {
						goods_id: item.goods_id,
						num: item.nums
					};
				})
			)
		};
		await addCartAllApi(params);
		const res2 = await carListApi();
		console.log('购物车列表', res2.data?.Items);
		context.commit('setCarList', res2.data?.Items);
		console.log('合并', context);
	}, 0)

	// async mergeCartList(context) {
	// 	console.log('合并购物车');
	// 	const { state } = context;
	// 	const params = {
	// 		data: JSON.stringify(
	// 			state.carList.map((item) => {
	// 				return {
	// 					goods_id: item.goods_id,
	// 					num: item.nums
	// 				};
	// 			})
	// 		)
	// 	};
	// 	await addCartAllApi(params);
	// 	const res2 = await carListApi();
	// 	console.log('购物车列表', res2.data?.Items);
	// 	context.commit('setCarList', res2.data?.Items);
	// 	console.log('合并', context);
	// }
};
